import "index.css"

import "lazysizes"
import $ from "cash-dom"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

document.body.className = document.body.className.replace("no-js","")

$(document).on('click', '[data-youtube]', function(e) {
  e.preventDefault();
  $(this).html('<iframe class="w-full aspect-video" src="https://www.youtube-nocookie.com/embed/' + $(this).data('youtube') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')
})
